<template>
    <div>
       <a-page-header
            :title="$t('companyprofile.title')"
            class="header_title"
        />
    <div class="box_content">
      <a-spin :spinning="spinning">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        <a-form-item :label="$t('companyprofile.item_1')">
          <a-input
            v-decorator="['company_name', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_2')">
          <a-input
            v-decorator="['contact_name', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_3')">
          <a-input
            v-decorator="['contact_no', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
            </a-form-item>
          <a-form-item :label="$t('companyprofile.item_4')">
          <a-input
            v-decorator="['company_address', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_5')">
          <a-input
            v-decorator="['email', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
      
        <a-form-item :label="$t('companyprofile.item_6')">
          <a-input
            v-decorator="['website', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Target">
          <a-input
            v-decorator="['target', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="BestBuy">
          <a-input
            v-decorator="['best_buy', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Instagram">
          <a-input
            v-decorator="['instagram', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Facebook">
          <a-input
            v-decorator="['facebook', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="YouTube">
          <a-input
            v-decorator="['you_tube', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>


        <a-form-item :label="$t('companyprofile.item_7')">
          <!-- <a-input
            v-decorator="['about', { rules: [{ required: true, message: $t('message.info')  }] }]"
          /> -->
          <a-textarea
              placeholder=""
              v-decorator="[
                  'about',
                  { rules: [{ required: true, message: $t('message.info') },{max: 300, message: $t('message.infoMax300'), trigger: 'blur' },] },
              ]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_8')">
          <a-upload
            name="file"
            :multiple="true"
            :headers="headers"
            :default-file-list="defaultFileList"
            action="https://labs.datahunt.app/index.php/upload"
            @change="handleChange"
          >
            <a-button v-if="isShow"> <a-icon type="upload" /> {{$t('companyprofile.btn_upload')}} </a-button>
            <span>{{path}}</span>
          </a-upload>
        </a-form-item>

        <a-form-item :label="$t('companyprofile.item_9')">
          <a-upload
            name="file"
            :multiple="true"
            :headers="headers"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :default-file-list="fileList"
            action="https://labs.datahunt.app/index.php/upload"
            :before-upload="beforeUpload"
            @change="handleChange2"
          >
            <img v-if="file_cover" :src="get_file_cover()" alt="cover" style="width: 100px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                {{$t('companyprofile.btn_upload')}}
                (150px*85px)
              </div>
            </div>
          </a-upload>

        </a-form-item>
        
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" class='ant-btn-danger' html-type="submit">
            {{$t('companyprofile.btn_submit')}}
          </a-button>
        </a-form-item>
      </a-form>
      </a-spin>
      </div>
    </div>
</template>

<script>
import {editProfile,getProfile} from '@/network/common'

export default {
    name: 'NodeprojectCompanyprofile',
    components: {  },
    directives: {  },
    data() {
        return {
             loading:false,
             spinning: true,
             delayTime: 500,
             profileData:{},
             formLayout: 'horizontal',
             form: this.$form.createForm(this, { name: 'coordinated' }),
             fileList: [],
             file_cover:'',
             defaultFileList:[],
             headers: {
              'X-Requested-With': null,
            },
            path:'',
            img_path:'',
            isShow:true,
        };
    },
    created() {
        getProfile()
        .then(res=>{
          this.spinning = false;
          // console.log(res)
          if(res.header.code==200){
            // this.form=res.body.profile
            // this.form.email=res.body.profile.email
            this.profileData=res.body.profile
            this.form.setFieldsValue({
              email:res.body.profile.email,
              website:res.body.profile.website,
              company_name:res.body.profile.company_name,
              company_address:res.body.profile.company_address,
              contact_no:res.body.profile.contact_no,
              contact_name:res.body.profile.contact_name,
              about:res.body.profile.about,
              
              target:res.body.profile.target,
              best_buy:res.body.profile.best_buy,
              instagram:res.body.profile.instagram,
              facebook:res.body.profile.facebook,
              you_tube:res.body.profile.you_tube,
            });
            let file_doc = res.body.profile.file_doc;
            // console.log(file_doc);
            if(file_doc){
              this.defaultFileList = [{
                uid: '1',
                name: file_doc,
                status: 'done',
                response: 'success',
                url: 'https://labs.datahunt.app/'+file_doc,
              }];
              this.path = file_doc;
              // this.isShow = false;
            }else{
              this.isShow = true;
            }
            this.fileList =[{
              uid: '2',
              name: res.body.profile.file_cover,
              status: 'done',
              response: 'success',
              url: 'https://labs.datahunt.app/'+res.body.profile.file_cover,
            }];
            this.file_cover = res.body.profile.file_cover;
          }
        })
    },
    beforeCreate() {
   
    },
    methods: {
      get_file_cover(){
        return 'https://labs.datahunt.app/'+this.file_cover;
      },
     handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.spinning = true;
          values.brand_name=this.profileData.brand_name;
          values.logo=this.profileData.logo;
          values.file_doc = this.path;
          values.file_cover = this.file_cover;
          editProfile(values)
          .then(res=>{
            this.spinning = false;
            if(res.header.code==200){
               this.$message.success('save success!');
               return ;
            }
            this.$message.error(res.header.msg);
          })

        }
      });
    },
    handleChange(info) {
      console.log(info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'removed') {
        this.isShow = true;
        this.path = '';
      }else if (info.file.status === 'done') {
        if(info.file.response.code == 0){
          this.isShow = false;
          this.path = info.file.response.path;
        }
        this.$message.success('file uploaded successfully');
      } else if (info.file.status === 'error') {
        this.$message.error('file upload failed');
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.=
          this.file_cover = info.file.response.path;;
          this.loading = false;
      }
        // console.log(info.fileList)
        // this.fileList=info.fileList;
        // this.file_cover = info.fileList[0].response.path;
        //  if(info.file.status==="uploading"){
        //   this.fileList=info.fileList 
        // }
        // if(info.file.status==="removed"){
        //     this.fileList=info.fileList 
        // }
        // if(info.file.status==='done'){
           
        // }
    },
    beforeUpload(file) {
        var that = this;
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const bigLt2M = file.size / 1024 / 1024 < 2;
        return new Promise((resolve, reject) => {
            if (!(isJPG || isJPEG || isPNG)) {
            that.$message.error('You can only upload JPG file!');
            reject(file);
            }else if (!bigLt2M) {
            that.$message.error('Image must smaller than 2MB!');
            reject(file);
            }else {
            resolve(file && this.checkImageWH2(file, 150, 85));
            }
      });
    },
    checkImageWH2(file, width, height) {
      var that = this;
      return new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width);
            console.log('file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            resolve();
            if (this.width == width && this.height == height)
            {
              resolve();
            } else {
              that.$message.error('Please upload '+width+'px*'+height+'px image');
              reject();
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    rejectImgs(fileList){
        // console.log(fileList)
    },
  },
};
</script>

<style>

</style>